<template>
  <div class="material-video-box">
    <el-dialog :visible.sync="visible" :close-on-click-modal="false" :show-close="false" width="850">
      <div slot="title">添加课程</div>
      <div style="border-bottom:1px solid #E9EAEB">
        <div class="flex-start mgn-b20 pdg-lr20">
          <div class="tabs pdg-lr20">
            <el-tabs v-model="searchData.sourceType" @tab-click="handleClick">
              <el-tab-pane label="我的课程" name="0"></el-tab-pane>
              <el-tab-pane label="企业采购" name="1"></el-tab-pane>
            </el-tabs>
          </div>
          <div class="material-video-search-box">
            <el-input
              placeholder="搜索内容"
              suffix-icon="el-icon-search"
              @change="onSearchChange"
              v-model="searchData.courseName">
            </el-input>
          </div>
          <div class="w100v pdg-lr10">
            <el-select v-model="searchData.classifyId" @change="onSelectChange" placeholder="选择分类">
              <el-option
                v-for="item in classifyList"
                :key="item.id"
                :label="item.classifyName"
                :value="item.id">
              </el-option>
            </el-select>
          </div>
        </div>
        <template v-for="(item,index) of tableDataList">
          <div :key="index" class="flex-start vertical-center pdg-tb10 pdg-lr20" :class="index%2===0?'bgf6f7fb':''">
            <el-checkbox v-model="item.isChecked" @change="onCheckedChange(item)" :disabled="searchData.sourceType == 1 && !item.residueDegree"></el-checkbox>
            <div class="video-info-box flex-start mgn-l20">
              <div class="video-cover">
                <img :src="item.coverUrl.middle" alt="" style="width:100%;height:100%;">
              </div>
              <div class="video-describe-box">
                <div class="pdg-b12 col272e40">{{ item.title }}</div>
                <div class="col7784a1 font-s14">
                  <span class="mgn-r20">共{{ item.videoCount }}个视频</span>
                  <span class="mgn-r20">累计{{ parseFloat((item.totalTime / 60).toFixed(1)) }}分钟</span>
                  <span class="mgn-r20">{{ item.examCount }}道问题</span>
                  <span class="mgn-r20" v-show="searchData.sourceType == 1">剩余分配{{ item.residueDegree || 0 }}次</span>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-if="tableDataList.length===0" class="col909399 text-ct pdg30">暂无数据</div>
        <el-pagination class="sorter-box"
                       @current-change="onCurrentChange"
                       :current-page="searchData.pageNo"
                       :page-size="searchData.pageSize"
                       :total="total"
                       background
                       hide-on-single-page
                       layout="total, prev, pager, next, jumper"
        ></el-pagination>
      </div>
      <div slot="footer" class="flex-center">
        <el-button @click="onClose">取消</el-button>
        <el-button :loading="btnLoading"  type="primary" @click="onSubmit">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'MaterialVideo',
  props: {
    visible: {       // 是否显示，默认true
      name: 'MaterialCourse',
      props: {
        visible: {       // 是否显示，默认true
          type: Boolean,
          default: true,
        },
        initCheckedList: {
          type: Array,
          default: () => {
            return []
          }
        }
      }
    }
  },
  components: {},
  watch: {
    visible(newVal, oldVal) {
      if (newVal) {
        this.initPage()
      }
    }
  },
  data() {
    return {
      // -------------------- 搜索、页数 -----------------
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 5,
        classifyId: '',
        courseName: '',
        sourceType: '0',
      },
      // ---------------------- select -----------------
      classifyList: [],
      // -------------------- submit ----------------
      btnLoading: false,
      checkedList: [],
      checkedIdArr: [],
      // ----------------------- vedio table -----------------
      tableDataList: [],
      //
    }
  },
  methods: {
    ...mapActions(['onTrainClassifyList', 'onTrainCourseList']),
    handleClick(tab) {
      if (tab.name === '1') {
        this.searchData.sourceType = '1'
        this.onCourseList()
      } else {
        this.onCourseList()
      }
    },
    onClose() {
      this.$emit('onClose', false)
    },
    onSubmit() {
      // this.checkedList.forEach(value => {
      //   value['sourceType'] = this.searchData.sourceType
      // })
      this.$emit('onSubmit', this.checkedList)
      this.onClose()
    },
    onSearchChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onCourseList()
    },
    onSelectChange() {
      this.$set(this.searchData, 'pageNo', 1)
      this.onCourseList()
    },
    initPage() {
      this.searchData = {
        pageNo: 1,
        pageSize: 10,
        classifyId: '',
        courseName: '',
        sourceType: '0',
      }
      this.checkedList = []
      this.tableDataList = []
      this.onCheckedIdArr()
      this.onCourseList()
      this.onGetVideoCategoryList()
    },
    onGetVideoCategoryList() {
      this.onTrainClassifyList().then(res => {
        let list = [
          {
            id: '',
            classifyName: '全部'
          }
        ]
        this.classifyList = list.concat(res.data || [])
      })
    },
    onCourseList() {
      this.onTrainCourseList(this.searchData).then(res => {
        this.total = res.data.totalCount || 0
        this.tableDataList = JSON.parse(JSON.stringify(res.data.data || []))
        this.tableDataList.map((it, ind) => {
          if (this.checkedIdArr.includes(it.id)) {
            this.$set(it, 'isChecked', true)
          } else {
            this.$set(it, 'isChecked', false)
          }
        })
      })
    },
    onCheckedIdArr() {
      let arr = []
      for (let item of this.checkedList) {
        arr.push(item.id)
        arr.push(item.source)
      }
      this.checkedIdArr = arr
    },
    onCheckedChange(item) {
      this.$set(item, 'isChecked', item.isChecked)
      let num = 0
      this.checkedList.map((it, ind) => {
        if (it.id !== item.id) {
          num += 1
        } else {
          if (!item.isChecked) {
            this.checkedList.splice(ind, 1)
          }
        }
      })
      if (num === this.checkedList.length && item.isChecked) {
        this.checkedList.push(item)
      }
      this.onCheckedIdArr()
    },
    onChange(options) {
      console.log(options)
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
      this.onCourseList()
    },
  }
}

</script>

<style>
.material-video-search-box .el-input input {
  width: 250px;
  border-radius: 20px;
}

.material-video-box .el-dialog__body {
  padding: 30px 0 !important;
}
</style>
<style scoped>
.video-cover {
  width: 107px;
  height: 60px;
  background: #D8D8D8;
  margin-right: 20px;
}

.bgf6f7fb {
  background: #f6f7fb;
}

.sorter-box {
  padding: 10px;
  text-align: right;
  background: #ffffff;
}
</style>
