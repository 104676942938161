<!--
 * @Author: your name
 * @Date: 2020-12-21 11:26:33
 * @LastEditTime: 2021-03-26 10:04:17
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/bizComponents/BindMobileModal.vue
-->
<template>
  <el-dialog :visible="visible" :show-close='false' class="factory-modal" width='360px'>
		<div class="content-box">
			<img src="@/assets/training/icon_phone.png" alt="">
			<iframe class="content-iframe" :src="previewUrl" frameborder="0"></iframe>
		</div>
		<div class="flex level-center vertical-center footer">
			<div class="flex vertical-center level-center btn pointer btn-default" @click="onPreview" v-if="showSend">发送到手机预览</div>
			<div class="flex vertical-center level-center btn pointer" @click="onClose">关闭预览</div>
		</div>
  </el-dialog>
</template>
<script>
import domainRoute from '@/utils/domain-route'
export default {
  name: 'PreviewCourseModal',
  data() {
    return {
			loading:false,
			previewUrl:'',
      noShowBtn:'show'
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    previewId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'studyDetail'
    },
    previewType: {
      type: String,
      default: 'studyPre'
    },
    showSend: {
      type: Boolean,
      default: true
    },
  },
  computed: {
  },
  methods: {
		onClose(){
			this.$emit('onClose')
		},
		onPreview(){
			this.$emit('onPreview')
		}
  },
  watch: {
    visible (open) {
      if (open) {
				this.previewUrl = domainRoute.coursePreview(this.previewId, this.type, this.previewType, this.noShowBtn)
      }
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
$color-primary: #3D61E3;
.el-button {
  width: 150px;
}
.factory-modal ::v-deep .el-dialog__header {
  display: none;
}
.factory-modal ::v-deep .el-dialog {
  background: unset;
  box-shadow: unset;
}
.factory-modal ::v-deep .el-dialog__body {
  padding: 0;
  max-height: 380px;
  display: flex;
  flex-direction: column;
}
.factory-modal ::v-deep .el-dialog__footer {
  border-top: 1px solid #E9EAEB;
  padding: 30px 70px;
  display: flex;
  justify-content: center;
}
.title {
  color: #272E40;
  font-size: 24px;
  flex-shrink: 0;
  padding: 30px 70px 30px;
}
.content {
  padding: 0 70px;
  color: #272E40;
}
.hint {
  margin: 20px 0;
  color: #adadad;
}
.content-box {
  position: relative;
  img {
    width: 100%;
  }
  .content-iframe {
    position: absolute;
    top: 63px;
    left: 21px;
    height: 573px;
    width: 319px;
  }
}
.btn {
  padding: 10px 12px;
  background: white;
  font-size: 14px;
  border-radius: 20px;
  width: 140px;
  margin: 0 7px;
  color: $color-primary;
  &.btn-default {
    background: $color-primary;
    color: white;
  }
}
.footer {
  margin-top: 15px;
}
</style>
