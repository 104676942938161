<!--
 * @Author: your name
 * @Date: 2020-12-21 11:26:33
 * @LastEditTime: 2022-03-28 10:38:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory_vue/src/bizComponents/BindMobileModal.vue
-->
<template>
  <el-dialog  :visible="visible" class="factory-modal" width='450px' :title='title' :before-close="onClose">
		<div class="content">
			<div>
				<input type="text" placeholder='请输入接收预览的手机号码' maxlength="11" v-model="mobile" class="input-mobile">
			</div>
			<div class="hint">预览功能仅用于代表端查看效果，不适用于正式发布。预览内容会在短期内失效。</div>
			<div>预览功能使用流程：</div>
			<el-row type="flex" align='middle' class="rules-box">
				<i class="el-icon-success"></i>
				<div>1.代表注册身份</div>
			</el-row>
			<el-row type="flex" align='middle' class="rules-box">
				<i class="el-icon-success"></i>
				<div>2.关注趣科会公众号并登录，
					<el-popover
						placement="top"
						trigger="click"
					>
						<img src="@/assets/training/ksh_mr_qr.png" alt="" class="qr-image">
						<span slot="reference" class="pointer">点击此处关注></span>
					</el-popover>
				</div>
			</el-row>
			<el-row type="flex" align='middle' class="rules-box">
				<i class="el-icon-success"></i>
				<div>3.输入手机号发送预览</div>
			</el-row>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onClose">取消</el-button>
			<el-button type="primary" @click="onSubmit">发送预览</el-button>
		</span>
  </el-dialog>
</template>
<script>
export default {
  name: 'SendPreviewModal',
  data() {
    return {
			loading:false,
			mobile:'',
    }
  },
  props:['visible','title'],
  computed: {
  },
  methods: {
    onClose () {
      this.$emit('onClose')
		},
		onSubmit(){
			this.$emit('onSubmit',this.mobile)
		},
  },
  watch: {
    visible (open) {
      if (open) {
				this.mobile = ''
      }
    }
  },
  mounted () {

  }
}
</script>
<style lang="scss" scoped>
$color-primary: #3D61E3;

.el-button {
  width: 150px;
}

.factory-modal ::v-deep .el-dialog__body {
  padding: 0;
  max-height: 380px;
  display: flex;
  flex-direction: column;
}

.factory-modal ::v-deep .el-dialog__footer {
  border-top: 1px solid #E9EAEB;
  padding: 30px 70px;
  display: flex;
  justify-content: center;
}

.title {
  color: #272E40;
  font-size: 24px;
  flex-shrink: 0;
  padding: 30px 70px 30px;
}

.content {
  padding: 40px 60px;
  color: #272E40;
}

.hint {
  margin: 10px 0 20px 0;
  font-size: 14px;
  color: rgb(202, 203, 207);
}

.input-mobile {
  width: 100%;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  padding: 4px 11px;
  height: 32px;
  line-height: 1.5;
  border-radius: 4px;
}

.rules-box {
  margin-top: 2px;

  i {
    color: rgb(149, 214, 0);
    margin-right: 5px;
  }
}

.qr-image {
  width: 150px;
  height: 150px;
}
</style>
