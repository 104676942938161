<template>
  <div class="page-body-box">
    <page-main back title class="content-box">
      <el-steps :active="1" align-center class="steps">
        <el-step title="1.新建培训内容"></el-step>
        <el-step title="2.选择任务对象" status="wait"></el-step>
        <el-step title="3.确认分配"></el-step>
      </el-steps>
      <div class="col272e40 font-s14 bgffffff mgn-t30 mgn-lr50">
        <div class="pdg-lr60 pdg-tb30" style="border-bottom:1px solid #E9EAEB;">
          <div class="flex-start">
            <div class="grow-shrink0">培训标题：</div>
            <div class="w100v pdg-lr10">
              <el-input
                type="text"
                placeholder="请输入培训标题"
                maxlength="30"
                show-word-limit
                v-model="saveData.title"
              ></el-input>
            </div>
          </div>
          <div class="flex-start vertical-start mgn-t30">
            <div class="grow-shrink0">培训简介：</div>
            <div class="w100v pdg-lr10">
              <el-input
                type="textarea"
                placeholder="请输入培训简介"
                maxlength="400"
                show-word-limit
                rows="8"
                v-model="saveData.introduction"
              ></el-input>
            </div>
          </div>
          <div class="flex-start vertical-start mgn-t30">
            <div class="grow-shrink0">培训封面：</div>
            <div class="w100v pdg-lr10 flex-start vertical-end course-fen-cover">
              <el-upload
                :action='uploadApi'
                :headers='headers'
                name='image'
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                list-type='picture-card'
                accept='image/gif,image/jpeg,image/jpg,image/png,image/svg'
              >
                <img v-if="courseCover" :src="courseCover" style="width:100%;height:100%;">
                <div v-else slot="trigger" class="wrapper">
                  <div>上传封面</div>
                </div>
              </el-upload>
              <span class="mgn-l10 colc8c9ce">备注：封面可添加培训主题/主讲人等信息，建议尺寸750*420</span>
            </div>
          </div>
          <div class="flex-start mgn-t30">
            <div class="grow-shrink0">培训时间：</div>
            <div class="w100v pdg-lr10">
              <el-date-picker
                v-model="trainTime"
                :picker-options="pickerOptions"
                :editable="false"
                @change="onChangeTime"
                type="datetimerange"
                format="yyyy/MM/dd HH:mm"
                range-separator="—"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>

            </div>
          </div>
        </div>
        <div class="pdg-lr60 pdg-t30 pdg-b120" style="border-bottom:1px solid #E9EAEB;">
          <div class="flex-start">
            <div class="grow-shrink0">培训课程：</div>
            <div class="w100v pdg-lr10 flex-start">
              <el-button plain type="primary" @click="onOpenCoursePop">添加课程</el-button>
              <span class="mgn-l10 colc8c9ce">备注：可上传多个课程，目录会按课程顺序自动排列</span>
            </div>
          </div>
          <div class="mgn-t20" style="border-left:1px solid #ebeef5;border-right:1px solid #ebeef5;">
            <el-table
              :data="tableData"
              stripe
              style="width: 100%">
              <el-table-column
                prop=""
                label="课程"
                align="center"
                width="180">
                <template slot-scope="scope">
                  <div class="video-cover"><img :src="scope.row.coverUrl&&scope.row.coverUrl.small" alt=""
                                                style="width:100%;height:100%;"></div>
                </template>
              </el-table-column>
              <el-table-column
                :show-overflow-tooltip="true"
                prop="title"
                label="课程名称">
              </el-table-column>
              <el-table-column
                prop="videoCount"
                label="视频个数"
                align="center"
                width="180">
              </el-table-column>
              <el-table-column
                prop="examCount"
                label="问题总数"
                align="center"
                width="180">
              </el-table-column>
              <el-table-column
                prop="trainRank"
                label="排序"
                align="center"
                width="180">
                <template slot-scope="scope">
                  <div>
                    <el-button v-if="scope.$index===0" class="mgn-r10" type="text"><img
                      src="../../assets/iconImg/icon-blod-up.png" alt=""></el-button>
                    <el-button v-else type="text" @click="onUp(scope)"><img
                      src="../../assets/iconImg/icon-blod-up-blue.png" alt=""></el-button>
                    <el-button v-if="scope.$index===(tableData.length-1)" type="text"><img
                      src="../../assets/iconImg/icon-blod-down.png" alt=""></el-button>
                    <el-button v-else type="text" @click="onDown(scope)"><img
                      src="../../assets/iconImg/icon-blod-down-blue.png" alt=""></el-button>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                align="center"
                label="操作">
                <template slot-scope="scope">
                  <div>
                    <el-button class="mgn-r10" type="text" @click="onPreview(scope.row.id,scope.row.title)">预览
                    </el-button>
                    <el-button type="text" @click="onDelCourse(scope)">删除</el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <div v-show="isShowToast" class="toast-box" :class="{'errorBg':isErrorBg}">{{ toastMessage }}</div>
    </page-main>
    <fixed-action-bar>
      <el-button @click="onBack">返回</el-button>
      <el-button @click="onSaveDraftBox(false)">保存</el-button>
      <el-button type="primary" @click="onSaveData">下一步</el-button>
    </fixed-action-bar>

    <MaterialCourse :visible="isShowCoursePop" @onSubmit="onSubmitMaterialVideo" @onClose="onClosePop"/>

    <OperationConfirmPop :visible="isShowOperationConfirmPop"
                         :message="message"
                         title="操作确认"
                         cancelFont="取消"
                         confirmFont="保存"
                         @onClose="onCloseOperationPop"
                         @onSubmit="onSubmitPop"/>

    <PreviewCourseModal :visible='showPreviewModal' :previewId='previewId' :type='type' :previewType='previewType' @onPreview='onPreviewMobile'
                        @onClose='onCloseModal'/>
    <SendPreviewModal :visible='showPreviewMobileModal' :previewId='previewId' @onSubmit='onSendToMobile'
                      @onClose='onCloseModal' title='课程预览'/>
  </div>
</template>

<script>
import MaterialCourse from '@/bizComponents/MaterialCourse'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import API from '@/apis/api-types'
import { getToken } from '@/utils/auth'
import { mapActions, mapGetters } from 'vuex'
import PreviewCourseModal from '@/bizComponents/PreviewCourseModal'
import SendPreviewModal from '@/bizComponents/SendPreviewModal'

export default {
  name: 'CreateTrain',
  components: {
    MaterialCourse,
    OperationConfirmPop,
    PreviewCourseModal,
    SendPreviewModal,
  },
  computed: {
    uploadApi() {
      return `${API.uploadImage}?bucketType=104`
    },
    changeSaveData() {
      return JSON.stringify(this.saveData) + JSON.stringify(this.tableData)
    }
  },
  watch: {
    changeSaveData(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.isSaveConfirm = false
      } else {
        this.isSaveConfirm = true
      }
    }
  },
  data() {
    return {
      headers: {
        token: getToken()
      },
      timerId: null,
      isShowToast: false,
      toastMessage: '',
      isErrorBg: false,
      isSaveConfirm: false,
      // ---------------------- upLoad ------------------
      imgUrl: '',
      // ---------------------- 弹窗 ------------------------
      isShowCoursePop: false,
      isShowOperationConfirmPop: false,
      // -------------------------- operationConfirmPop 离开确认--------------------
      message: '当前页面未编辑完成，是否保存当前编辑到草稿箱？',
      // ----------------------- vedio table -----------------
      courseIdArr: [],
      tableData: [],  // 培训视频列表
      // --------------------------------- 取消 保存 ------------------------
      courseCover: '',     // 培训封面
      trainTime: [],
      saveData: {
        id: '',
        title: '',    // 标题
        introduction: '',   //简介
        coverPath: '',    // 封面路径
        startTime: '',   // 培训时间start
        endTime: '',   // 培训时间end
        source: 0,    // 来源（0：厂家；1：平台）
      },
      pickerOptions: {
        disabledDate(time) {
          // return time.getTime() > Date.now(); //今天之后禁用
          return time.getTime() < (Date.now() - (24 * 60 * 60 * 1000)) //今天之前禁用，减去一天的毫秒数，否则今天也将禁用
        }
      },
      // ------------------- 预览 -----------------------------
      showPreviewModal: false,//预览modal
      showPreviewMobileModal: false,//发送手机预览modal
      previewId: '',//预览活动的id
      active: 0,
      previewType: 'studyPre',
      type: 'studyDetail',
    }
  },
  mounted() {
    if (this.$route.params.trainId) {
      this.descText = '编辑培训'
      this.onInitTrainData()
    }
  },
  methods: {
    ...mapActions([
      'onTrainModifyProgram',
      'onTrainProgramDataInfo',
      'onSendPreview',
    ]),
    onClosePop(options) {
      this.isShowCoursePop = false
      this.isShowOperationConfirmPop = false
    },
    onBack() {
      this.$router.replace('/train/programs')
    },
    onCloseOperationPop() {
      this.isShowOperationConfirmPop = false
    },
    onOpenCoursePop() {
      this.isShowCoursePop = true
    },
    onGoAddTrainMember(data) {
      this.$router.replace({ name: 'AddTrainMember', params: { trainId: data.id } })
    },
    initPage() {
      this.saveData = {
        id: '',
        title: '',    // 标题
        introduction: '',   //简介
        coverPath: '',    // 封面路径
        startTime: '',   // 培训时间start
        endTime: '',   // 培训时间end
        source: 0,    // 来源（0：厂家；1：平台）
      }
      this.courseCover = ''
      this.tableData = []
    },
    goBack() {
      this.$router.replace('/train/programs')
    },
    onInitTrainData() {
      this.onTrainProgramDataInfo({ programId: this.$route.params.trainId }).then(res => {
        const resData = res.data || {}
        this.courseCover = resData.coverUrl && resData.coverUrl.middle || ''
        this.saveData = {
          id: resData.id,
          title: resData.title,
          introduction: resData.introduction,
          coverPath: resData.coverUrl && resData.coverUrl.imageName,
          startTime: resData.startTime,   // 培训时间start
          endTime: resData.endTime,   // 培训时间end
        }
        this.trainTime = resData.startTime && resData.endTime ? [resData.startTime, resData.endTime] : [],
          this.tableData = resData.courseVOS
        this.onCourseIdArr()
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onCourseIdArr() {
      let arr = []
      for (let item of this.tableData) {
        arr.push(item.id)
      }

      this.courseIdArr = arr
    },
    onChangeTime(options) {
      if (!options) {
        return false
      }
      let date = new Date()
      this.$set(this.saveData, 'startTime', this.$moment(options[0]).format('yyyy-MM-DD HH:mm:ss'))
      this.$set(this.saveData, 'endTime', this.$moment(options[1]).format('yyyy-MM-DD HH:mm:ss'))
      // this.$set(this.saveData,'startTime',new Date(options[0]).getTime());
      // this.$set(this.saveData,'endTime',new Date(options[1]).getTime());
    },
    beforeAvatarUpload(file) {
      let _this = this
      const isSize = new Promise(function (resolve, reject) {
        let width = 750 // 限制图片尺寸为750*420
        let height = 420
        let _URL = window.URL || window.webkitURL
        let img = new Image()
        img.onload = function () {
          let valid = img.width === width && img.height === height
          valid ? resolve() : reject()
        }
        img.src = _URL.createObjectURL(file)
      }).then(() => {
        return file
      }, () => {
        _this.$message.warning('建议封面图片尺寸750*420')
        return Promise.resolve()
      })
      return isSize
    },
    handleAvatarSuccess(res, file) {
      this.courseCover = URL.createObjectURL(file.raw)
      this.$set(this.saveData, 'coverPath', res.data.imageName)
    },
    onSubmitMaterialVideo(options) {
      options.map((it, ind) => {
        if (!this.courseIdArr.includes(it.id)) {
          this.tableData.push(it)
        } else {
          this.$set(this.tableData, this.courseIdArr.indexOf(it.id), it)
        }
      })
      this.onCourseIdArr()
    },
    onSubmitPop() {
      let courseList = []
      this.tableData.map((item, index) => {
        courseList.push({
          courseId: item.id,
          courseIndex: index,
          sourceType: item.source
        })
      })
      this.saveData = {...this.saveData, status: 3}
      let Data = Object.assign({}, {
        programsTrain: this.saveData,
        programCourses: courseList,
      })
      this.onTrainModifyProgram(Data).then(res => {
        this.isShowToast = true
        this.toastMessage = '保存成功'
        this.isErrorBg = false
        this.isSaveConfirm = true
        this.onClosePop()
        setTimeout(() => {
          this.goBack()
        }, 1000)
      }, rea => {
        this.onClearTimeout()
        this.onClosePop()
        this.isShowToast = true
        this.toastMessage = rea.message
        this.isErrorBg = true
        this.isSaveConfirm = false
      })
    },
    onOpenOperationConfirmPop() {
      if (!this.isSaveConfirm) {
        this.isShowOperationConfirmPop = true
      } else {
        this.goBack()
      }
    },
    onUp(options) {
      console.log(options)
      this.tableData.splice(options.$index, 1)
      this.tableData.splice(options.$index - 1, 0, options.row)
      this.onCourseIdArr()
    },
    onDown(options) {
      console.log(options)
      this.tableData.splice(options.$index, 1)
      this.tableData.splice(options.$index + 1, 0, options.row)
      this.onCourseIdArr()
    },
    onDelCourse(scope) {
      this.tableData.splice(scope.$index, 1)
      this.onCourseIdArr()
    },
    onCheckData(isShow) {
      this.timerId && clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        this.isShowToast = false
      }, 3000)
      if (!this.saveData.title) {
        this.isShowToast = isShow
        this.toastMessage = '请填写培训标题'
        this.isErrorBg = isShow
        return false
      } else if (!this.saveData.introduction) {
        this.isShowToast = isShow
        this.toastMessage = '请填写培训简介'
        this.isErrorBg = isShow
        return false
      } else if (!this.saveData.coverPath) {
        this.isShowToast = isShow
        this.toastMessage = '请上传培训封面'
        this.isErrorBg = isShow
        return false
      } else if (!(this.trainTime.length > 0)) {
        this.isShowToast = isShow
        this.toastMessage = '请选择培训时间'
        this.isErrorBg = isShow
        return false
      }

      if (!(this.tableData.length > 0)) {
        this.isShowToast = isShow
        this.toastMessage = '请添加课程'
        this.isErrorBg = isShow
        return false
      }
      return true
    },
    onClearTimeout() {
      this.timerId && clearTimeout(this.timerId)
      this.timerId = setTimeout(() => {
        this.isShowToast = false
      }, 3000)
    },
    onSaveDraftBox(isBack = false) {
      if (isBack && !this.saveData.title) {
        this.goBack()
        return
      }
      this.onClearTimeout()
      if (!this.saveData.title) {
        this.isShowToast = true
        this.toastMessage = '请填写培训标题'
        this.isErrorBg = true
        return false
      }

      let isSubmit = this.onCheckData(false)
      if (!isSubmit) {
        this.message = '当前页面未编辑完成，是否保存当前编辑到草稿箱？'
      } else {
        this.message = '是否保存当前编辑到草稿箱？'
      }
      this.onOpenOperationConfirmPop()
    },
    onSaveData() {
      let isSubmit = this.onCheckData(true)
      if (!isSubmit) return false
      let courseList = []
      this.tableData.map((item, index) => {
        courseList.push({
          courseId: item.id,
          courseIndex: index,
          sourceType: item.source
        })
      })
      let Data = Object.assign({}, {
        programsTrain: this.saveData,
        programCourses: courseList,
      })
      this.onTrainModifyProgram(Data).then(res => {
        this.onClosePop()
        this.isShowToast = true
        this.toastMessage = '保存成功'
        this.isErrorBg = false
        this.isSaveConfirm = true
        this.onGoAddTrainMember(res.data)
      }, rea => {
        this.onClearTimeout()
        this.onClosePop()
        this.isShowToast = true
        this.toastMessage = rea.message
        this.isErrorBg = true
        this.isSaveConfirm = false
      })
    },
    // ------------------------ 预览 -------------------
    onCloseModal() {
      this.showPreviewModal = false
      this.showPreviewMobileModal = false
      this.previewId = ''
      this.previewTitle = ''
    },
    onPreview(id, title) {
      this.previewId = id
      this.previewTitle = title
      this.showPreviewModal = true
    },
    onPreviewMobile() {
      this.showPreviewModal = false
      this.showPreviewMobileModal = true
    },
    onSendToMobile(mobile) {
      let req = {
        representMobile: [mobile],
        sceneId: this.previewId,
        sceneType: 'training',
        sceneTitle: this.previewTitle
      }
      this.onSendPreview(req).then(res => {
        this.$message.success('发送成功')
        this.onCloseModal()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
  }
}
</script>

<style>
.course-fen-cover .el-upload--picture-card {
  width: 160px;
  height: 90px;
  border-radius: 0;
  background-color: #e2e3e7;
  border: 1px dashed #3D61E3;
}
</style>
<style scoped>
.steps {
  margin-top: 30px;
}

.wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  line-height: 1;
  height: 100%;
  color: #3D61E3;
  font-size: 14px;
  font-weight: bold;
}

.content-box::-webkit-scrollbar {
  width: 0 !important;
}

.video-cover {
  width: 107px;
  height: 60px;
  background: #D8D8D8;
  margin-right: 20px;
}

.toast-box {
  color: #ffffff;
  background: green;
}

.errorBg {
  background: #d8384a;
}

</style>
