<template>
  <div class="add-train-member-box page-body-box">
    <PageNavBack
      :primary-text="primaryText"
      :desc-text="descText + ' 添加培训对象'"
      back-text="返回培训活动"
      show-back
      @goBack="goBack"
    />
    <el-steps :active="2" align-center class="steps mgn-t30">
      <el-step title="1.新建培训内容" />
      <el-step title="2.选择任务对象" />
      <el-step title="3.确认分配" status="wait" />
    </el-steps>
    <page-main class="content-box pdg-t30">
      <div class="col272e40 font-s14 bgffffff mgn-lr50 pdg-lr30 pdg-b30 content">
        <div class="col272e40 pdg-tb30">
          <span class="font-weight">培训活动：</span>
          <span class="col7784a1">{{ title }}</span>
        </div>
        <div class="col272e40">
          <span class="font-weight">奖励积分：</span>
          <el-input
            v-model="money"
            placeholder="请输入积分数"
            style="width: 160px;"
            type="number"
            min="0"
            max="1000"
          />
          <span class="colc8c9ce mgn-l8">备注：代表完成培训可以获得奖励积分，积分额度在0-1000之间</span>
        </div>
        <div class="flex-start pdg-tb30">
          <span class="font-weight">培训对象：</span>
          <el-button plain class="mgn-r10" @click="onOpenCheckTreamMemberPop">添加培训对象</el-button>
          <el-button plain @click="onShowAddExcelModal">批量导入</el-button>
        </div>
        <template>
          <div class="allot-body">
            <scene-allot-for-rep-item
              v-for="current in allotRepMap"
              :key="current.value"
              :title="current.name"
              :code-money="money"
              :scene-type="sceneType"
              :represent-list="representList | allotCardFilter(current.value)"
              @onDelCard="onDelCard(current.value)"
              @onDelRep="onDelRep"
              @onChangeInvite="e => onChangeRepInvite(e, current.value)"
            />
          </div>
        </template>
      </div>
      <div v-show="isShowToast" class="toast-box" :class="{ errorBg: isErrorBg }">{{ toastMessage }}</div>
    </page-main>
    <fixed-action-bar class="allot-footer">
      <div>
        <el-row class="footer-title">
          <span>任务总数：{{ totalInviteCount }}</span>
          <span style="margin-left: 30px;">预计使用积分：{{ totalCost }}</span>
        </el-row>
        <div class="col7784a1 mgn-t5">账户可支配余额：{{ disposableAmount }}</div>
      </div>
      <el-row type="flex" align="middle">
        <el-button plain type="primary" @click="onEditorTrain">上一步</el-button>
        <el-button type="primary" @click="onNext">下一步</el-button>
      </el-row>
    </fixed-action-bar>
    <SelectOrgRepresentModel
      v-if="isShowSelectModel"
      type="train"
      :scene-id="trainId"
      :is-show-pop="isShowSelectModel"
      :init-select-list="representList"
      @onConfirmSelect="onConfirmSelect"
      @onClose="
        val => {
          isShowSelectModel = val
        }
      "
    />
    <scene-allot-modal
      :visible="showAllotModal"
      :scene-data="allotData"
      :allot-list="representList"
      :allot-type="allotType"
      :scene-type="sceneType"
      :loading="isSubmit"
      @onSubmit="onSubmit"
      @onClose="onClosePop"
    />
    <payment-captcha-modal
      :visible="showWechatValidModal"
      code-type="4"
      :check-value="totalCost"
      @onSuccess="onWxPaySuccess"
      @closeModal="onClosePop"
    />
    <payment-limit-modal :visible="showPayLimitModal" @closeModal="onClosePop" />
    <ImportExcelDialog
      v-if="isShowImport"
      :is-show-pop="isShowImport"
      model-type="allotRepresent"
      @onComplete="onComplete"
      @onClose="
        val => {
          isShowImport = val
        }
      "
    />
  </div>
</template>

<script>
import PageNavBack from '@/bizComponents/PageNavBack'
import SceneAllotForRepItem from '../academic/child/SceneAllotForRepItem'
import SceneAllotModal from '../academic/child/SceneAllotModal'
import PaymentLimitModal from '@/bizComponents/PaymentLimitModal'
import PaymentCaptchaModal from '@/bizComponents/PaymentCaptchaModal'
import SelectOrgRepresentModel from '@/bizComponents/SelectOrgRepresentModel'
import ImportExcelDialog from '@/baseComponents/ImportExcelDialog'
import { mapActions } from 'vuex'

export default {
  name: 'AddTrainMember',
  components: {
    PageNavBack,
    SceneAllotForRepItem,
    SceneAllotModal,
    PaymentLimitModal,
    PaymentCaptchaModal,
    SelectOrgRepresentModel,
    ImportExcelDialog
  },
  filters: {
    allotCardFilter(data, currentKey) {
      return data.filter(v => v.orgId === currentKey || v.teamId === currentKey)
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$nextTick(() => {
        if (from.name === 'EditTrain' || from.name === 'CreateTrain') {
          vm.savePage = true
        }
        if (from.name === 'LookTrainData') {
          vm.isSavePage = true
        }
        vm.fromPageName = from.name
        vm.primaryText = '培训活动 - '
      })
    })
  },
  data() {
    return {
      users: [],
      primaryText: '培训活动 - 创建培训 - ',
      descText: '',
      timerId: null,
      fromPageName: '',
      title: '',
      trainId: null,
      isShowToast: false,
      isErrorBg: false,
      toastMessage: '',
      code: '',
      representQuotaBos: [],
      representList: [],
      isShowSelectModel: false,
      // ---------------------- 弹窗 ------------------------
      sceneId: '', // 场景id
      allotType: 'represent', // represent department doctor
      showPayLimitModal: false,
      showWechatValidModal: false,
      payTicket: '', // 支付扫码密钥
      isSubmit: false,
      showAllotModal: false,
      savePage: false,
      isSavePage: false,
      doctorStudyDate: 0,
      money: '', // 任务额度
      disposableAmount: '',
      representValidDay: 0, // 代表执行时间
      sceneType: 7, // 代表执行时间
      isPopupWindow: false, // 显示上传excel弹窗
      isShowImport: false
    }
  },
  computed: {
    allotRepMap() {
      // 返回需要遍历的卡片
      const obj = {}
      this.representList.map(v => {
        const key = v.orgId || v.teamId
        const name = v.orgName || v.teamName
        if (!obj[key]) {
          obj[key] = name
        }
      })
      let output = []
      for (const i in obj) {
        output = [...output, { value: i, name: obj[i] }]
      }
      return output
    },
    totalInviteCount() {
      const total = this.representList.length || 0
      return total
    },
    totalCost() {
      return this.totalInviteCount * this.money
    },
    allotData() {
      return {
        totalInviteCount: this.totalInviteCount,
        money: this.money,
        title: this.title,
        representValidDay: this.representValidDay,
        doctorStudyDate: this.doctorStudyDate,
        totalCost: this.totalCost,
        userLess: this.disposableAmount
      }
    }
  },
  mounted() {
    this.trainId = this.$route.params.trainId
    this.getFactoryAllotCode({ sceneId: this.trainId, sceneType: 'training' }).then(
      res => {
        this.title = res.data.title
      },
      rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      }
    )
    this.accountUserSummary().then(res => {
      this.disposableAmount = res.data.disposableAmount
    })
  },
  destroyed() {
    this.timerId && clearTimeout(this.timerId)
  },
  methods: {
    ...mapActions(['getFactoryAllotCode', 'onGetJsonRepresentList', 'allotSceneCode', 'checkCourseDegree', 'accountUserSummary']),
    goBack() {
      this.$router.replace('/train/programs')
    },
    onShowAddExcelModal() {
      this.isShowImport = true
    },
    onComplete(data) {
      const arr = [...this.representList].map(v => v.representId)
      data.results.map(v => {
        if (arr.indexOf(v.id) < 0 && !v.errorReason) {
          this.representList = [
            ...this.representList,
            {
              ...v,
              representId: v.id,
              representName: v.name,
              representStatus: null,
              representDoctorCount: v.doctorCount,
              representMobile: v.repMobile,
              inviteCount: v.giftCount,
              key: `${v.id  }-${  v.orgId}`,
              isSelect: true
            }
          ]
        }
      })
    },
    onDelRep(currentRepId) {
      this.representList = [...this.representList].filter(v => v.representId !== currentRepId)
    },
    onDelCard(currentCardId) {
      this.representList = [...this.representList].filter(v => v.orgId !== currentCardId)
    },
    onChangeRepInvite(count, key) {
      this.representList = [...this.representList].map(v => {
        if (v.orgId === key || v.teamId === key) {
          v.inviteCount = count
        }
        return v
      })
    },
    onTrigger(item) {
      this.$set(item, 'isOpen', !item.isOpen)
    },
    onDelList(item, index) {
      this.representList.splice(index, 1)
    },
    onClosePop() {
      this.isShowSelectModel = false
      this.showAllotModal = false
      this.showWechatValidModal = false
      this.showPayLimitModal = false
    },
    onOpenCheckTreamMemberPop() {
      this.isShowSelectModel = true
    },
    onEditorTrain() {
      if (this.savePage) {
        this.$router.replace(`/edit/train/${this.trainId}`)
      } else if (this.isSavePage) {
        this.$router.replace(`/look/train/data/${this.trainId}`)
      } else {
        this.goBack()
      }
    },
    onGetDataId() {
      const list = []
      for (const item of this.representList) {
        list.push(item.orgId)
      }
      return list
    },
    // onGetTrainRepresents(options) {
    //     this.representList = options
    //     this.onClosePop()
    // },
    onConfirmSelect(list) {
      const initSelectList = []
      for (const v of list) {
        initSelectList.push({
          ...v,
          id: v.representId,
          repMobile: v.mobile
        })
      }
      this.representList = initSelectList
    },
    onGetRepresentQuotaBos(list) {
      for (const item of list) {
        if (item.child && item.child.length > 0) {
          this.onGetRepresentQuotaBos(item.child)
        } else {
          if (item.isChecked && !item.isTrainSelect) {
            this.representQuotaBos.push({
              representId: item.representId,
              codeCount: '1',
              orgId: item.orgId
            })
          }
        }
      }
    },
    onNext() {
      if (this.money < 0 || this.money > 1000) {
        return this.$message.error('积分额度应在0-200之间')
      }
      if (!this.representList.length) {
        return this.$message.error('请选择需要分配的任务对象')
      }
      const Data = {
        programId: this.trainId,
        distributeCount: this.totalInviteCount
      }
      this.checkCourseDegree(Data).then(
        res => {
          this.showAllotModal = res.data.isSupportDistribution
          this.isPopupWindow = res.data.isPopupWindow
        },
        rea => {
          this.$message({
            message: rea.message,
            type: 'error'
          })
        }
      )
    },
    onWxPaySuccess(ticket) {
      this.payTicket = ticket
      this.onSubmit()
    },
    onSubmit() {
      this.isShowOperationConfirmPop = false
      if (this.isSubmit) return
      const quotaBos = [...this.representList].map(v => {
        const output = { codeCount: 1, childOrgId: v.orgId || v.factoryId }
        output.representId = v.representId
        return output
      })
      const req = {
        ticket: this.payTicket,
        body: {
          quotaSceneInfo: {
            sceneId: this.trainId,
            money: this.money || 0,
            assignToUserType: '2',
            sceneType: 'training'
          },
          quotaBos: quotaBos,
          includeDelegation: false
        }
      }
      this.isSubmit = true
      this.allotSceneCode(req).then(
        () => {
          this.isSubmit = false
          this.$message.success('任务正在分配中···')
          setTimeout(() => {
            this.$router.push({ name: 'TrainPrograms' })
          }, 2000)
        },
        rea => {
          this.isSubmit = false
          if (rea === '需要微信扫码校验') {
            this.showWechatValidModal = true
          } else if (rea === '需要绑定手机号' || rea === '需要绑定微信号') {
            this.showPayLimitModal = true
          } else {
            this.$message.error(rea)
          }
        }
      )
    }
  }
}
</script>

<style>
.el-table .bgf6f7fb-row {
  background: #f6f7fb;
}
.foot-btn-item .el-button--text {
  color: #fff;
}
.content-tree-box .el-tree-node__content {
  height: 60px;
  line-height: 60px;
}
</style>
<style scoped lang="scss">
.content-box {
  position: relative;
  flex: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
.content {
  padding: 20px 60px 110px 60px;
  min-height: 80vh;
}
.content-box::-webkit-scrollbar {
  width: 0 !important;
}
.footer-box {
  height: 100px;
}
.toast-box {
  color: #fff;
  background: green;
}
.member-team-title-box {
  height: 100px;
  border-radius: 4px 4px 0 0;
}
.operation-img {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.operation-img-item {
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
}
.scene-item {
  padding-top: 10px;
  padding-bottom: 10px;
  &.row-line {
    border-bottom: 1px solid #e9eaeb;
    margin: -20px -60px 20px;
    padding: 30px 60px;
  }
}
.scene-label {
  color: #272e40;
  font-weight: bold;
  flex-shrink: 0;
}
.scene-content {
  color: #7784a1;
  flex: 1;
}
.common-margin {
  margin-bottom: 10px;
}
.allot-body {
  padding-top: 20px;
}
.allot-footer {
  ::v-deep .actionbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-title {
      font-size: 24px;
      font-weight: bold;
      color: #2b3243;
      line-height: 1.5;
    }
  }
}
.errorBg {
  background: #d8384a;
}
</style>
