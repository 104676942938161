<template>
  <div class="recall-scene-code-modal">
    <el-dialog
      :visible.sync="visible"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <div class="font-weight" slot="title">撤回任务</div>
      <template>
        <div class="code-list-box">
          <div class="code-list-title">可撤回的任务：</div>
          <el-scrollbar>
            <div v-if="codeList.length > 0" class="code-list-label">
              <template v-for="(item, index) in codeList">
                <CodeLabel
                  :showTitle="true"
                  :key="index"
                  :item="item"
                  :index="index"
                  :sceneType="sceneType"
                />
              </template>
            </div>
            <div v-else>暂无</div>
          </el-scrollbar>
        </div>
      </template>
      <template>
        <div class="recall-scene-code-modal-checkbox">
          <el-table
            ref="multipleTable"
            :data="recallCodeList"
            tooltip-effect="dark"
            style="width: 100%"
            height="300px"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="100"> </el-table-column>
            <el-table-column prop="representName" label="代表姓名" width="200">
            </el-table-column>
            <el-table-column prop="orgName" label="组织" width="220">
            </el-table-column>
          </el-table>
          <div class="check-res">
            本次撤回任务{{ multipleSelection.length }}个
          </div>
        </div>
      </template>
      <div slot="footer" class="flex-center">
        <el-button class="w140 mgn-l20" @click="onClose">取消</el-button>
        <el-button type="primary" @click="onSubmit">确定 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import CodeLabel from "@/baseComponents/CodeLabel";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "WithdrawCodeModal",
  props: {
    visible: {
      // 是否显示，默认true
      type: Boolean,
      default: true,
    },
    programId: {
      // 场景id，
      type: String,
      default: "",
    },
    sceneType: {
      // 场景类型 科会->meeting；调研-> questionnaire；直播->live
      type: String,
      default: "",
    },
    count: {
      // 场景类型 科会->meeting；调研-> questionnaire；直播->live
      type: Number,
      default: 0,
    },
  },
  components: {
    CodeLabel,
  },
  data() {
    return {
      index: 0,
      codeList: [],
      loading: true,
      recallCodeList: [],
      multipleSelection: [],
      code: 0,
    };
  },
  watch: {
    visible(show) {
      if (show) {
        this.getWithdrawRepresentList({ programId: this.programId }).then(
          (res) => {
            this.codeList = res.data.codeInfos || [];
            this.recallCodeList = res.data.representsVOS || [];
          },
          (rea) => {
            this.$message.error(rea.message);
            this.codeList = [];
            this.recallCodeList = [];
          }
        );
      }
    },
  },
  mounted() {},
  methods: {
    ...mapActions(["getWithdrawRepresentList", "trainWithdrawCode"]),
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.code = 0;
      this.multipleSelection.map((v) => {
        this.code = this.code + v.money;
      });
    },
    onClose() {
      this.$emit("onClose", false);
    },
    onSubmit() {
      let representIds = [];
      this.multipleSelection.map((v) => {
        representIds.push(v.representId);
      });
      this.trainWithdrawCode({
        sceneId: this.programId,
        representIds: representIds,
        count: this.count - representIds.length,
      }).then(
        (res) => {
          this.$message.success("撤回成功");
          this.onClose();
        },
        (rea) => {
          this.$message.error(rea.message);
          this.onClose();
        }
      );
    },
  },
};
</script>
<style>
.recall-scene-code-modal .el-dialog {
  width: 850px;
}

.recall-scene-code-modal .el-dialog__body {
  padding-top: 0px;
}

.recall-scene-code-modal-checkbox .el-checkbox {
  color: #272e40;
}

.recall-scene-code-modal-checkbox .el-scrollbar__wrap {
  overflow-x: hidden;
}

.empty .el-input__inner {
  border: 1px solid red;
}

.checkbox-message-item .el-row {
  width: 100%;
}
</style>
<style scoped>
.code-list-box {
  height: 76px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.code-list-title {
  font-weight: bold;
  width: 100px;
  flex-shrink: 0;
  flex-grow: 0;
}

.code-list-label {
  display: flex;
  flex-flow: row nowrap;
}

.recall-scene-code-modal-checkbox {
  margin-top: 15px;
}

.table-empry {
  height: 50px;
  line-height: 50px;
  background: #ffffff;
  text-align: center;
  border-bottom: 1px solid #e9eaeb;
}

.checkbox-title {
  height: 50px;
  line-height: 50px;
  padding: 0 15px;
  background: #f6f7fb;
}

.checkbox-item:nth-child(even) {
  background: #f6f7fb;
}

.checkbox-item {
  height: 50px;
  line-height: 50px;
  padding-left: 30px;
  padding-right: 15px;
}

.checkbox-message-box {
  width: 100%;
}

.checkbox-message-item:nth-child(even) {
  background: #f6f7fb;
}

.checkbox-message-item {
  text-align: center;
  width: 100%;
  height: 50px;
  line-height: 50px;
  padding-right: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.check-res {
  padding: 25px;
}
</style>
