<template>
  <!-- look-train-data-box  跟文件名称对应 -->
  <div class="look-train-data-box page-body-box">
      <page-main back title class="col272e40 bgffffff box-shadow">
        <div class="flex-start vertical-start">
          <div class="grow-shrink0 mgn-r30" style="width:320px;height:180px;background:#D8D8D8;">
            <img v-if="baseDataInfo.coverUrl" :src="baseDataInfo.coverUrl.middle" alt=""
                 style="width:100%;height:100%;">
          </div>
          <div>
            <div class="flex-between flex-direction-col vertical-start">
              <div class="flex-start">
                <div class="col272e40 font-s18 font-bold">{{ baseDataInfo.title }}</div>
                <div v-if="baseDataInfo.status===0" class="table-box bgfef2de color-yellow">未开始</div>
                <div v-if="baseDataInfo.status===1" class="table-box bgdcedff col198cff">进行中</div>
                <div v-if="baseDataInfo.status===2" class="table-box bgeceef0 col97a1b6">已结束</div>
              </div>
              <div class="col7784a1 mgn-t10">
                <span class="mgn-r30">共{{ baseDataInfo.courseCount || 0 }}个课程</span>
                <span
                  v-if="baseDataInfo.startTime">培训时间：{{ baseDataInfo.startTime }} — {{ baseDataInfo.endTime }}</span>
                <span v-else>培训时间：暂无</span>
              </div>
              <div class="col7784a1 flex-start mgn-t10">
                <span class="mgn-r10">培训整体进度</span>
                <el-tooltip class="item" effect="dark"
                            :content="'代表已完成/培训代表总数：'+(baseDataInfo.finishedCount||0)+'/'+(baseDataInfo.count||0)"
                            placement="right-start">
                  <div class="out-progress">
                    <div v-if="baseDataInfo.finishedCount !== baseDataInfo.count||baseDataInfo.finishedCount===0"
                         class="in-progress-doing" :style="{'width':(baseDataInfo.process||0)*100+'%'}"></div>
                    <div v-else class="in-progress-end" style="width:100%;"></div>
                  </div>
                </el-tooltip>
              </div>
            </div>
            <div class="mgn-t30 col7784a1">
              <span>培训简介：</span>
              <span>{{ baseDataInfo.introduction }}</span>
            </div>
          </div>
        </div>
        <div class="mgn-t30">
          <div class="col272e40">本培训共{{ baseDataInfo.courseCount || 0 }}个课程</div>
          <div class="mgn-t10" style="border-left:1px solid #ebeef5;border-right:1px solid #ebeef5;">
            <el-table
              :data="baseDataInfo.courseVOS||[]"
              stripe
              style="width: 100%">
              <el-table-column
                prop="bianhao"
                label="排序"
                width="120">
                <template slot-scope="scope">
                  <div>
                    {{ (scope.$index + 1) > 9 ? (scope.$index + 1) : '0' + (scope.$index + 1) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop=""
                align="center"
                label="课程"
                width="180">
                <template slot-scope="scope">
                  <div class="video-cover">
                    <img :src="scope.row.coverUrl&&scope.row.coverUrl.small" alt=""
                                                style="width:100%;height:100%;"></div>
                </template>
              </el-table-column>
              <el-table-column
                prop="title"
                align="center"
                show-overflow-tooltip
                label="课程名称">
              </el-table-column>
              <el-table-column
                prop="videoCount"
                align="center"
                label="视频个数">
              </el-table-column>
              <el-table-column
                prop="totalTime"
                align="center"
                label="总视频时长">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.totalTime >= 60? parseInt(scope.row.totalTime/60)+'分'+scope.row.totalTime % (parseInt(scope.row.totalTime/60)*60)+'秒': scope.row.totalTime+'秒'}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="examCount"
                align="center"
                label="问题总数">
              </el-table-column>
              <el-table-column
                prop=""
                align="center"
                label="操作"
                width="150">
                <template slot-scope="scope">
                  <div>
                    <el-button class="mgn-r10" type="text" @click="onPreview(scope.row.id,scope.row.title)">预览
                    </el-button>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </page-main>
      <page-main class="title-nav-box col272e40 font-s14 bgffffff box-shadow">
        <div class="flex-between data">
          <div class="funnel">
            <EchartsFunnel :option="option" v-if="showFunnel"></EchartsFunnel>
          </div>
          <div class="title-list border">
            <div class="col272e40 font-weight font-s36">{{ baseProcessDataInfo.count || 0 }}</div>
            <div class="col7784a1 mgn-t27">总培训任务数</div>
          </div>
          <div class="title-list">
            <div class="col272e40 font-weight font-s36">
              {{
                (baseProcessDataInfo.process || 0) > 0 ? ((baseProcessDataInfo.process || 0) * 100).toFixed(1) + '%' : '0%'
              }}
            </div>
            <div class="col7784a1 mgn-t27">培训整体进度</div>
          </div>
          <div class="title-list">
            <div class="col272e40 font-weight font-s36">
              {{
                (baseProcessDataInfo.accuracy || 0) > 0 ? ((baseProcessDataInfo.accuracy || 0) * 100).toFixed(1) + '%' : '0%'
              }}
            </div>
            <div class="col7784a1 mgn-t27">答题准确率</div>
          </div>
          <div class="blank">
          </div>
          <div class="icon-message-box">
            <el-popover
              placement="left"
              width="400"
              trigger="hover"
            >
              <div>总培训任务：不包含撤回任务</div>
              <div>已开始任务：已经分配给代表且已开始学习的任务</div>
              <div>已完成任务：已经分配给代表且已完成的任务</div>
              <div>满分任务：已经结束且完成率100%的任务</div>
              <div>培训整体进度： 已完成的培训进度 / 总的培训数</div>
              <div>答题准确率：所有答对的题目 / 所有已答的题目</div>
              <img slot="reference" src="../../assets/iconImg/icon-mark.png" alt=""
                   style="width:100%;height:100%;cursor:pointer;">
            </el-popover>
          </div>

        </div>
        <div class="pdg30">
          <div class="flex-between">
            <span class="col272e40 font-s18 ">培训代表数共{{ baseProcessDataInfo.count || 0 }}人</span>
            <div class="export-input-box">
              <el-input class="export-input" v-model="representName" @input="onSearchName"
                        placeholder="请输入代表姓名"></el-input>
              <el-popover
                v-model='showExportPop'
                trigger="manual"
                placement='top'
              >
                <div class="lay-box">是否导出数据?</div>
                <el-row type="flex" justify="end">
                  <el-button size="mini" type="text" @click="showExportPop = false">取消</el-button>
                  <el-button type="primary" size="mini" @click="onExportProcessData">确定</el-button>
                </el-row>
              </el-popover>
              <el-button v-if="$auth('crm.training.exportExcel')" type="text" @click="showExportPop = true">导出数据为Excel</el-button>
            </div>

          </div>
          <div class="mgn-t10" style="border-left:1px solid #ebeef5;border-right:1px solid #ebeef5;">
            <el-table
              :data="tableData"
              stripe
              style="width: 100%">
              <el-table-column
                prop="name"
                align="center"
                label="姓名">
              </el-table-column>
              <el-table-column
                prop="orgName"
                align="center"
                label="所在组织">
              </el-table-column>
              <el-table-column
                prop="name"
                align="center"
                label="">
                <template slot="header" slot-scope="scope">
                  <el-select :value="ownerType" placeholder="培训状态" @change="onSelectChange">
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.status == '2' ? '已完成' : scope.row.status == '3' ? '已过期' : ' 未完成'}}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="process"
                align="center"
                width="200"
                label="培训完成度">
                <template slot-scope="scope">
                  <div class="flex-start" style="width:100%;align-items:baseline;">
                    <div class="out-progress-table">
                      <div v-if="((scope.row.process||0)*100).toFixed(1)<100"
                           class="in-progress-doing" :style="{'width':(scope.row.process||0)*100+'%'}">
                      </div>
                      <div v-else class="in-progress-end" style="width:100%;"></div>
                    </div>
                    <div style="width:60px;text-align:left;">
                      {{ (scope.row.process || 0) > 0 ? ((scope.row.process || 0) * 100).toFixed(1) + '%' : '0%' }}
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="accuracy"
                align="center"
                label="答案准确率">
                <template slot-scope="scope">
                  <div>
                    {{ (scope.row.accuracy || 0) > 0 ? ((scope.row.accuracy || 0) * 100).toFixed(1) + '%' : '0%' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="endTime"
                align="center"
                label="培训完成时间">
                <template slot-scope="scope">
                  <div>
                    {{ scope.row.endTime || '—' }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <div class="flex-center mgn-t10">
              <el-pagination @size-change="sizeChange"
                             @current-change="currentChange"
                             :current-page="page"
                             :page-size="size"
                             layout="total, prev, pager, next, jumper"
                             :total="total"
                             class="el-pagination">
              </el-pagination>
            </div>
          </div>
        </div>
      </page-main>
    <fixed-action-bar>
      <el-button v-if="$auth('crm.training.addTarget')" type="primary" @click="onGoAddTrainMember"
                 :disabled="baseDataInfo.status===2 || baseDataInfo.status===3">
        添加培训对象
      </el-button>
      <el-button v-if="$auth('crm.training.edit')" type="primary" plain @click="onEditorTrain" :disabled="baseDataInfo.isDistribution || baseDataInfo.status ===1 || baseDataInfo.status ===2">
        编辑活动
      </el-button>
      <el-button type="primary" v-if="$auth('crm.training.delete')" plain @click="onOpenOperationConfirmPop"
                 :disabled="baseDataInfo.isDistribution">删除活动
      </el-button>
      <el-button v-if="$auth('crm.training.revokeTask')" type="primary" plain @click="handleClick" :disabled="!baseDataInfo.haveWithdraw">撤回任务</el-button>
    </fixed-action-bar>
    <OperationConfirmPop :visible="isShowOperationConfirmPop"
                         :message="message"
                         :id="trainId"
                         title="活动删除操作确认"
                         cancelFont="取消"
                         confirmFont="确定"
                         @onSubmit="onDelTrainProgram"
                         @onClose="onClosePop"/>
    <PreviewCourseModal :visible='showPreviewModal' :previewId='previewId' :type='type' :previewType='previewType'
                        @onPreview='onPreviewMobile'
                        @onClose='onCloseModal'/>
    <SendPreviewModal :visible='showPreviewMobileModal' :previewId='previewId' @onSubmit='onSendToMobile'
                      @onClose='onCloseModal' title='课程预览'/>
    <WithdrawResources
      :visible="dialogFormVisible"
      :programId="trainId"
      :sceneType="sceneType"
      :count=baseDataInfo.count
      @onClose="onRecallSceneCodeModalClose"/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PreviewCourseModal from '@/bizComponents/PreviewCourseModal'
import SendPreviewModal from '@/bizComponents/SendPreviewModal'
import OperationConfirmPop from '@/bizComponents/OperationConfirmPop'
import EchartsFunnel from '@/baseComponents/EchartsFunnel.vue'
import WithdrawResources from '@/bizComponents/WithdrawResources'
import { JsonToExcel } from '@/utils/util'

const typeMap = new Map([
  [-1, '医生已领取'],
  ['-1', '医生已领取'],
  [1, '医生待领取'],
  ['1', '医生已领取'],
  [2, '代表未分配'],
  ['2', '代表未分配'],
  [3, '组织未分配'],
  ['3', '组织未分配'],
])
export default {
  name: 'LookTrainData',          // 跟文件名称对应
  components: {
    PreviewCourseModal,
    SendPreviewModal,
    OperationConfirmPop,
    EchartsFunnel,
    WithdrawResources
  },
  filters: {
    typeStr(type) {
      return typeMap.get(type) || '—'
    }
  },
  data() {
    return {
      // ------------------ table header select ----------------
      options: [
        {
          value: '',
          label: '全部'
        },
        {
          value: '0',
          label: '未完成'
        },
        {
          value: '2',
          label: '已完成'
        },
        {
          value: '3',
          label: '已过期'
        }
      ],
      ownerType: '',
      representName: '',
      // ------------------------ table -------------------------
      baseDataInfo: {},
      baseProcessDataInfo: {},
      initRepresentDataVOS: [],
      representDataVOS: [],
      // ------------------- 预览 -----------------------------
      showPreviewModal: false,//预览modal
      showPreviewMobileModal: false,//发送手机预览modal
      previewId: '',//预览活动的id
      isShowOperationConfirmPop: false,
      message: '请确认是否永久删除该培训？',
      trainId: '',
      dialogFormVisible: false,
      sceneType: 'training',
      trainData: [
        { value: 80, name: '总培训任务：' },
        { value: 60, name: '已开始任务：' },
        { value: 40, name: '已完成任务：' },
        { value: 20, name: '满分任务：' },
      ],
      showFunnel: false,
      previewType: 'studyPre',
      type: 'studyDetail',
      page: 1, //第几页
      size: 10, //一页多少条
      total: 0, //总条目数
      pageSizes: [10, 20, 50, 100, 200, 300, 400, 500],
      tableData: [],
      showExportPop: false,
      option: {}
    }
  },
  computed: {
    table() {
      let list = []
      list = this.representDataVOS.filter(item => {
          return item.name?.includes(this.representName)
        }
      )
      list = list.filter(item => {
        let status = item.status === 0 || item.status === 1 || item.status === 4 ? '0' : item.status
        if (this.ownerType) {
            return status == this.ownerType
          } else {
            return true
          }
        }
      )
      return list
    }
  },
  mounted() {
    this.trainId = this.$route.params.trainId
    this.init()
  },
  methods: {
    ...mapActions(['onTrainProgramDataInfo', 'onTrainProgramProcessData', 'onTrainExportProcessData', 'onSendPreview', 'onProgramTotalData', 'onTrainRemoveProgram', 'codeUsedStatistic']),
        getOption() {
      this.option = {
        legend: [],
        tooltip: {
          trigger: 'item',
          formatter: '{a} <br/>{b}'
        },
        series: [{
          name: '活动数据',
          type: 'funnel',
          left: 0,
          top: 0,
          bottom: 0,
          width: 240,
          min: 0,
          max: 100,
          minSize: '0%',
          maxSize: '100%',
          gap: 0,
          label: {
            show: true,
            position: 'right',
            color: '#272E40'
          },
          labelLine: {
            length: 30,
            lineStyle: {
              width: 1,
              type: 'solid'
            }
          },
          color: [
          '#B1BFF3',
          '#8AA0EE',
          '#6380E8',
          '#3D61E3'
          ],
          data: this.trainData
        }],

      }
      this.showFunnel = true
    },
    init() {
      this.onGetTrainDataInfo()
      this.onTrainProgramTotalData()
      this.onGetProcessData()
      this.onCodeUsedStatistic()
    },
    onGetTrainDataInfo() {
      this.onTrainProgramDataInfo({ programId: this.$route.params.trainId }).then(res => {
        this.baseDataInfo = res.data || {}
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onTrainProgramTotalData() {
      this.onProgramTotalData({ programId: this.$route.params.trainId }).then(res => {
        this.baseProcessDataInfo = res.data || {}
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    onGetProcessData() {
      this.onTrainProgramProcessData({ programId: this.$route.params.trainId }).then(res => {
        this.representDataVOS = res.data || []
        this.getTableData(this.table)
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
      })
    },
    getTableData(representData) {
      let data = JSON.parse(JSON.stringify(representData))
      this.tableData = data.splice(
        (this.page - 1) * this.size,
        this.size
      )
      this.total = representData.length
    },
    //page改变时的回调函数，参数为当前页码
    currentChange(val) {
      this.page = val
      this.getTableData(this.table)
    },
    //size改变时回调的函数，参数为当前的size
    sizeChange(val) {
      this.size = val
      this.page = 1
      this.getTableData(this.representDataVOS)
    },
    onSearchName() {
      this.getTableData(this.table)
    },
    onSelectChange(val) {
      this.page = 1
      this.ownerType = val
      this.getTableData(this.table)
    },
    onCodeUsedStatistic() {
      this.codeUsedStatistic({ sceneId: this.$route.params.trainId }).then(res => {
        this.trainData = [...this.trainData].map((v, i) => {
          let name = ''
          if (i === 0) {
            name = '总培训任务：' + res.data.totalCodeCount + '个，' + '共' + res.data.totalCodeMoney + '积分'
          } else if (i === 1) {
            name = '已开始任务：' + res.data.beginCodeCount + '个，' + '共' + res.data.beginCodeMoney + '积分'
          } else if (i === 2) {
            name = '已完成任务：' + res.data.finishedCodeCount + '个，' + '共' + res.data.finishedCodeMoney + '积分'
          } else if (i === 3) {
            name = '满分任务：' + res.data.perfectNumberCodeCount + '个，' + '共' + res.data.perfectNumberCodeMoney + '积分'
          }
          v = { ...v, name: name }
          return v
        })
        this.getOption()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onExportProcessData() {
      if (!this.table.length) return this.$message.error('暂无需要导出的数据')
      let json = [...this.table].map(item => {
        return [item.id, item.name, item.orgName, item.status == '2' ? '已完成' :  item.status == '3' ?'已过期' : '未完成', (item.process * 100).toFixed(1) + '%', (item.accuracy * 100).toFixed(1) + '%', item.endTime || '/']
      })
      let header = ['编号', '姓名', '所在组织', '状态', '培训完成度', '答题准确率', '培训完成时间']
      JsonToExcel(`培训代表列表`, [header, ...json])
      this.showExportPop = false
    },
    // ------------------------ 预览 -------------------
    onCloseModal() {
      this.showPreviewModal = false
      this.showPreviewMobileModal = false
      this.previewId = ''
      this.previewTitle = ''
    },
    onPreview(id, title) {
      this.previewId = id
      this.previewTitle = title
      this.showPreviewModal = true
    },
    onPreviewMobile() {
      this.showPreviewModal = false
      this.showPreviewMobileModal = true
    },
    onSendToMobile(mobile) {
      let req = {
        representMobile: [mobile],
        sceneId: this.previewId,
        sceneType: 'training',
        sceneTitle: this.previewTitle
      }
      this.onSendPreview(req).then(res => {
        this.$message.success('发送成功')
        this.onCloseModal()
      }, rea => {
        this.$message.error(rea.message)
      })
    },
    onGoAddTrainMember() {
      this.$router.push({ name: 'AddTrainMember', params: { trainId: this.$route.params.trainId } })
    },
    onOpenOperationConfirmPop() {
      this.isShowOperationConfirmPop = true
    },
    onDelTrainProgram(id) {
      this.onTrainRemoveProgram({ programId: id }).then(res => {
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.$router.back()
        this.onClosePop()
      }, rea => {
        this.$message({
          message: rea.message,
          type: 'error'
        })
        this.onClosePop()
      })
    },
    onClosePop() {
      this.isShowOperationConfirmPop = false
    },
    onEditorTrain() {
      this.$router.push({ name: 'EditTrain', params: { trainId: this.trainId } })
    },
    handleClick() {
      this.dialogFormVisible = true
    },
    onRecallSceneCodeModalClose() {
      this.dialogFormVisible = false
      this.init()
    },
  },
}
</script>

<style>
.el-pagination .el-pager .number {
  border-right-width: 0px;

}
</style>
<style lang="scss" scoped>
.content-box {
  position: relative;
  flex: 1;
  overflow-y: scroll;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  margin: $g-main-wrap-margin;
}

.content-box::-webkit-scrollbar {
  width: 0 !important;
}

.footer-box {
  height: 100px;
}

.toast-box {
  color: #ffffff;
  background: green;
}

.table-box {
  width: 54px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 15px;
  margin-left: 10px;
}

.out-progress {
  width: 140px;
  height: 10px;
  background: #F0F0F0;
  border-radius: 7px;
}

.out-progress-table {
  flex: 1;
  height: 10px;
  background: #F0F0F0;
  border-radius: 7px;
  margin-right: 5px;
}

.in-progress-doing {
  height: 10px;
  background: linear-gradient(270deg, #3DC8FB 0%, #417BF3 100%);
  border-radius: 7px;
}

.in-progress-end {
  height: 10px;
  background: linear-gradient(270deg, #00EA6E 0%, #14B4C2 100%);
  border-radius: 7px;
}

.export-input-box {
  width: 500px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}

.export-input {
  width: 350px;
  margin-right: 20px;
}

.sorter-box {
  padding: 30px;
  text-align: right;
  background: #ffffff;
}

.video-cover {
  width: 107px;
  height: 60px;
  background: #D8D8D8;
  margin: auto;
}

.box-shadow {
  box-shadow: 2px -2px 4px 0px #e2e3e7;
}

.title-nav-box {
  position: relative;
  z-index: 10;
}

.title-list {
  text-align: center;
  /*padding: 10px 100px 10px;*/
}

.icon-message-box {
  width: 24px;
  height: 24px;
  overflow: hidden;
  position: absolute;
  z-index: 100;
  top: 20px;
  right: 20px;
}

.funnel {
  width: 400px;
  height: 150px;
  margin: 30px 10px;
}

.border {
  padding-left: 80px;
  border-left: 1px solid #E9EAEB;
}

.data {
  border-bottom: 1px solid #E9EAEB;
}

.blank {
  width: 20px;
}
</style>
