<!--
 * @Author: your name
 * @Date: 2020-12-10 14:43:48
 * @LastEditTime: 2021-08-25 14:58:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /ksh-factory-vue/src/bizComponents/PageNav.vue
-->
<template>
  <el-row type="flex" class="page-nav-box" justify="space-between">
    <div class="page-nav-title">
      <span class="color-primary">{{ primaryText }}</span>
      <span>{{ descText }}</span>
    </div>
    <div class="color-primary back-btn" @click="goBack" v-if="showBack">{{ backText }}</div>
  </el-row>
</template>
<script>
export default {
  name: 'PageNavBack',
  data() {
    return {}
  },
  props: {
    showBack: {
      type: Boolean,
      default: false,
    },
    backText: {
      type: String,
      default: '返回上一页',
    },
    primaryText: {
      type: String,
      default: '',
    },
    descText: {
      type: String,
      default: '',
    },
  },
  methods: {
    goBack() {
      this.$emit('goBack')
    },
  },
}
</script>
<style lang="scss" scoped>
.back-btn {
  position: relative;
  padding-left: 30px;
  line-height: 30px;
  height: 30px;
  font-weight: 500;
  cursor: pointer;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 24px;
    background: url(../assets/nav/back.png) no-repeat center center;
    background-size: 100%;
  }
}
</style>
