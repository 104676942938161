<template>
  <page-main class="customize-page-main">
    <el-tabs v-model="searchData.status" class="manage-tabs" @tab-click="handleClick">
      <el-tab-pane label="全部" name="all" />
      <el-tab-pane label="未开始" name="0" />
      <el-tab-pane label="进行中" name="1" />
      <el-tab-pane label="已结束" name="2" />
      <el-tab-pane label="草稿箱" name="3" />
    </el-tabs>
    <div ref="content" v-loading="loading" class="content">
      <div class="flex-end right-control">
        <div class="course-management-custom-input-box user-input-box">
          <el-input v-model="searchData.title" placeholder="搜索培训活动名称">
            <el-button
              slot="append"
              class="search-box"
              icon="el-icon-search"
              @click="onSearch"
            />
          </el-input>
        </div>
        <el-button v-if="$auth('crm.training.create')" type="primary" @click="onGoCreateTrain">创建培训</el-button>
      </div>
      <div
        ref="scroll-wrapper"
        v-infinite-scroll="onTrainList"
        :infinite-scroll-immediate="false"
        :infinite-scroll-distance="50"
        :infinite-scroll-disabled="loading || isEnd"
      >
        <div class="course-box">
          <div class="course-list-box">
            <div v-if="!trainProgramList.length" class="col909399 text-ct pdg30" style="width: 100%;">暂无数据</div>
            <el-row :gutter="10" type="flex" class="flex-wrap">
              <el-col
                v-for="item in trainProgramList"
                :key="item.id"
                :xs="8"
                :sm="8"
                :md="6"
                :lg="4"
                :xl="4"
              >
                <div class="course-item-box cursor" @click.stop="onGoLookTrainData(item.id)">
                  <div style="width: 100%; height: 135px; background: #d8d8d8; position: relative;">
                    <img
                      v-if="item.coverUrl"
                      :src="item.coverUrl.middle"
                      alt=""
                      style="width: 100%; height: 135px; object-fit: cover;"
                    >
                    <div class="flex-start time">
                      <img
                        v-if="item.status===0"
                        src="../../assets/training/icon_train_not_start.png"
                        alt=""
                        class="table-box"
                      >
                      <img
                        v-if="item.status===1"
                        src="../../assets/training/icon_train_progress.png"
                        alt=""
                        class="table-box"
                      >
                      <img
                        v-if="item.status===2"
                        src="../../assets/training/icon_train_closed.png"
                        alt=""
                        class="table-box"
                      >
                    </div>
                  </div>
                  <div class="course-item-message">
                    <div class=" pdg-lr15 pdg-t10 course-title">{{ item.title }}</div>
                    <span v-if="item.startTime" class="col7784a1 pdg-lr15 pdg-t10 font-s12">
                      {{ $moment(item.startTime).format('YYYY-MM-DD') }} -
                      {{ $moment(item.endTime).format('YYYY-MM-DD') }}
                    </span>
                    <span v-else class="col7784a1 pdg-lr15 pdg-t10 font-s12">培训时间：暂无</span>
                    <div class="col7784a1 flex-start pdg-lr15 pdg-t10">
                      <el-tooltip
                        class="item"
                        effect="dark"
                        :content="'代表已完成/培训代表总数：'+item.finishedCount+'/'+item.count"
                        placement="right-start"
                      >
                        <div class="out-progress">
                          <div
                            v-if="item.finishedCount !== item.count||item.finishedCount===0"
                            class="in-progress-doing"
                            :style="{'width':(item.process||0)*100+'%'}"
                          />
                          <div v-else class="in-progress-end" style="width: 100%;" />
                        </div>
                      </el-tooltip>
                    </div>
                    <div class="flex-between pdg-lr15 pdg-t10 pdg-b5">
                      <div
                        v-if="item.status!==2&&item.status!==3"
                        class="col198cff font-weight"
                        @click.stop="onGoAddTrainMember(item.id)"
                      >
                        <i class="el-icon-circle-plus" />添加培训对象
                      </div>
                      <div
                        v-if="item.status===3"
                        class="col198cff font-weight"
                        @click.stop="onEditorTrain(item.id)"
                      >
                        <img
                          src="../../assets/training/icon_editor.jpg"
                          alt=""
                          style="width: 12px; height: 12px;"
                        >编辑草稿
                      </div>
                      <div v-if="item.status===2" class="col7784a1">
                        培训已结束
                      </div>
                      <i class="el-icon-s-tools col7784a1 font-s20 pointer" />
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </page-main>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'TrainPrograms',
  beforeRouteLeave(to, from, next) {
    this.scrollTop = document.documentElement.scrollTop + document.body.scrollTop
    if (to.name === 'LookTrainData') {
      this.isSavePage = true
    } else {
      this.isSavePage = false
    }
    next()
  },
  data() {
    return {
      trainId: '',
      isSavePage: false,
      // -------------------- 搜索、页数 -----------------
      timerId: [],
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 24,
        title: '',
        status: 'all'
      },
      // ----------------------------------- trainList ------------------
      trainList: [],
      // ------------------------- progress ----------------
      activeProgress: {
        width: `${(100 / 140) * 100  }%`
      },
      // -------------------------- operationConfirmPop 操作弹窗--------------------
      message: '请确认是否永久删除该培训？',
      loading: false,
      isEnd: false,
      pageNo: 1,
      scrollTop: 0
    }
  },
  computed: {
    ...mapGetters(['trainProgramList'])
  },
  activated() {
    document.documentElement.scrollTop = this.scrollTop
  },
  mounted() {
    if (!this.isSavePage) {
      this.$set(this.searchData, 'pageNo', 1)
      this.initPage()
    }
  },
  methods: {
    ...mapActions(['getTrainProgramList', 'onTrainRemoveProgram']),
    initPage() {
      this.searchData = Object.assign({}, { pageNo: 1, pageSize: 24, title: '', status: 'all' })
      this.onTrainList()
    },
    onTrainList() {
      this.onClearTime()
      const timer = setTimeout(() => {
        this.onInitTrainProgramList()
      }, 300)
      this.timerId.push(timer)
    },
    handleClick() {
      this.loading = false
      this.isEnd = false
      this.searchData = Object.assign({}, this.searchData, { pageNo: 1, title: '' })
      this.onInitTrainProgramList()
    },
    onClearTime() {
      for (const item of this.timerId) {
        clearTimeout(item)
      }
      this.timerId = []
    },
    onInitTrainProgramList() {
      if (this.isEnd || this.loading) return
      this.loading = true
      const Data = Object.assign({}, this.searchData)
      Data.status = Data.status === 'all' ? '' : Data.status
      this.getTrainProgramList(Data).then(res => {
        this.searchData.pageNo++
        this.isEnd = !res.length
        this.loading = false
      }, () => {
        this.loading = false
        this.isEnd = false
      })
    },
    onSearch() {
      this.loading = false
      this.isEnd = false
      this.$set(this.searchData, 'pageNo', 1)
      this.onInitTrainProgramList()
    },
    onGoCreateTrain() {
      this.$router.push({ name: 'CreateTrain' })
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
    },
    onGoAddTrainMember(id) {
      this.$router.push({ name: 'AddTrainMember', params: { trainId: id } })
    },
    onEditorTrain(id) {
      this.$router.push({ name: 'EditTrain', params: { trainId: id } })
    },
    onGoLookTrainData(id) {
      this.$router.push({ name: 'LookTrainData', params: { trainId: id } })
    }
  }
}
</script>

<style scoped lang="scss">
.page-body {
  padding-top: 0;
}
::v-deep .manage-tabs {
  .el-tabs__header {
    .el-tabs__nav-wrap {
      max-width: calc(100% - 410px);
    }
  }
}
.user-input-box {
  width: 300px;
  margin-right: 20px;
}
.is-fixed {
  position: sticky;
  top: 0;
  z-index: 15;
  background: #fff;
}
.table-box {
  display: -ms-flexbox;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  right: 7px;
  top: 7px;
  width: 54px;
  height: 24px;
}
.out-progress {
  width: 140px;
  height: 10px;
  background: #f0f0f0;
  border-radius: 7px;
}
.in-progress-doing {
  height: 10px;
  background: linear-gradient(270deg, #3dc8fb 0%, #417bf3 100%);
  border-radius: 7px;
}
.in-progress-end {
  height: 10px;
  background: linear-gradient(270deg, #00ea6e 0%, #14b4c2 100%);
  border-radius: 7px;
}
.course-box {
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  overflow: hidden;
}
.course-item-box {
  max-width: 240px;
  background: #fff;
  display: flex;
  flex-flow: column nowrap;
  margin-bottom: 10px;
}
.course-item-message {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  flex: 1;
}
.course-title {
  color: #272e40;
  font-size: 14px;
  font-weight: bold;
  height: 50px;
}
.content {
  background-color: #f6f7fb;
  flex: 1;
  position: relative;

  /* overflow-y: auto; */
  padding: 10px;
  padding-bottom: 0;
  .right-control {
    z-index: 10;
    position: absolute;
    top: -42px;
    right: 0;
  }
}
.course-list-box::-webkit-scrollbar {
  width: 0 !important;
}

</style>
